import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumberFixedPipe } from './pipes/number-fixed.pipe';

@NgModule({
  declarations: [NumberFixedPipe],
  exports: [NumberFixedPipe],
  imports: [
    CommonModule
  ]
})
export class NumberUtilsModule { }
