import { DictionaryItem } from 'quantuvis-angular-common/interfaces';

export enum PriceProtectionReset {
  EMPTY = 51,
  ANNUALLY,
  ANNUALLY_CY,
  CUMULATIVE,
  PRICE_INCREASE_REBATE_OFFER,
  MANAGE_WAC = 213
}

export namespace PriceProtectionReset {
  const valuesMap = new Map<PriceProtectionReset, DictionaryItem>([
    [PriceProtectionReset.EMPTY, { id: PriceProtectionReset.EMPTY, name: '' }],
    [PriceProtectionReset.ANNUALLY, { id: PriceProtectionReset.ANNUALLY, name: 'Annually' }],
    [PriceProtectionReset.ANNUALLY_CY, { id: PriceProtectionReset.ANNUALLY_CY, name: 'Annually - Contract Year' }],
    [PriceProtectionReset.CUMULATIVE, { id: PriceProtectionReset.CUMULATIVE, name: 'Cumulative' }],
    [PriceProtectionReset.PRICE_INCREASE_REBATE_OFFER, {
      id: PriceProtectionReset.PRICE_INCREASE_REBATE_OFFER,
      name: 'After Last Price Increase that Impacted Rebate Offer'
    }],
    [PriceProtectionReset.MANAGE_WAC, { id: PriceProtectionReset.MANAGE_WAC, name: 'Max Allowable WAC' }],
  ]);

  export function getValue(reset: PriceProtectionReset): DictionaryItem {
    return valuesMap.get(reset);
  }

  export function getValues(): DictionaryItem[] {
    return Array.from(valuesMap.values());
  }
}
