<div *blockUI="'template-form-modal'">
  <div mat-dialog-title class="qv-modal-dialog-title">
    <div class="qv-modal-dialog-title__text">{{modalData.title}}</div>
    <button class="qv-modal-dialog-title__xbutton" [mat-dialog-close]>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content class="qv-modal-dialog-content" [ngClass]="nestedCmp?.contentClass">
    <ng-container #nestedComponent></ng-container>
  </div>
  <div
    mat-dialog-actions
    class="qv-modal-dialog-actions qv-modal-dialog-actions--two-column"
    [ngClass]="nestedCmp?.dialogActionsBlockClass"
  >
    <div class="qv-modal-dialog-actions__left-block">
      <div
           *ngIf="nestedCmp?.infoMessage?.length"
           class="qv-modal-dialog-actions__info"
           [ngClass]="nestedCmp?.dialogInfoMessageBlockClass"
           [class.qv-modal-dialog-actions__info--checkbox]="isInfoMessageCheckboxShown"
           [class.qv-modal-dialog-actions__info--message-tooltip]="nestedCmp?.infoMessageTooltip"
           [class.qv-modal-dialog-actions__info--flex-align-center]="nestedCmp?.infoMessageTooltip || isInfoMessageCheckboxShown">
        <mat-checkbox
          *ngIf="isInfoMessageCheckboxShown"
          class="qv-checkbox qv-checkbox--primary"
          [checked]="isInfoMessageCheckboxChecked$ | async"
          (change)="onCheckBoxClick($event)">
        </mat-checkbox>
        <div class="qv-modal-dialog-actions__info-text">
          {{ nestedCmp.infoMessage }}
        </div>
        <div *ngIf="nestedCmp?.infoMessageTooltip?.length">
          <mat-icon
            [matTooltip]="nestedCmp.infoMessageTooltip"
            class="material-icons-outlined"
          >info</mat-icon>
        </div>
      </div>
    </div>
    <div class="qv-modal-dialog-actions__right-block">
      <button
        *ngIf="modalData.secondaryActionText"
        mat-flat-button
        tabindex="-1"
        class="qv-modal-dialog-actions__item"
        (click)="onSecondaryAction()"
      >{{ modalData.secondaryActionText }}</button>
      <span
        *ngIf="nestedCmp?.isShowPrimaryActionButton"
        [matTooltip]="nestedCmp?.primaryActionDisabledTooltip"
        [matTooltipDisabled]="
        !nestedCmp?.primaryActionDisabledTooltip
        || !isActionButtonDisabled && nestedCmp?.form?.valid && !nestedCmp?.isPrimaryActionButtonDisabled"
      >
        <button
          mat-flat-button
          tabindex="-1"
          class="qv-modal-dialog-actions__item qv-modal-dialog-actions__item--primary"
          [disabled]="
          nestedCmp?.form?.disabled
          || nestedCmp?.form?.invalid
          || nestedCmp?.form?.pending
          || isActionButtonDisabled
          || nestedCmp?.isPrimaryActionButtonDisabled"
          (click)="onPrimaryAction()"
        >{{ modalData.primaryActionText }}</button>
      </span>
    </div>
  </div>
</div>
